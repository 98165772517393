
import {
  defineComponent, ref, unref,
  toRefs, watch, onMounted
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import useFormErrors from '@/utils/validate';
import { Rules } from 'async-validator';
import { useCreateChannel, useUpdateChannel, useChannel } from '@/composables/api';
import { PartialChannel, Promotion, ChannelAgent } from '@/interfaces';
import { normalizeRequestData } from '@/utils/normalization';
import { CREATED_SUCCESSFULLY_TEXT, getChannelAgents, MAX_PER_PAGE, UPDATED_SUCCESSFULLY_TEXT } from '@/services/api';

const DEFAULT_FORM_VALUES: PartialChannel = {
  utmSource: '',
  promotion: null,
  // channelAgentId: null,
  note: ''
};

const rules: Rules = {
  utmSource: [
    {
      required: true,
      message: '此為必填欄位'
    }
  ],
  promotion: [
    {
      required: true,
      message: '此為必填欄位'
    }
  ],
  channelAgentId: [
    {
      required: true,
      message: '此為必填欄位'
    }
  ]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const channelId = useRoute().params.id as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isCreating, mutate: create } = useCreateChannel();
    const { isLoading: isUpdating, mutate: update } = useUpdateChannel();
    const { formErrors, bindFormItemError } = useFormErrors();
    const { data, dataUpdatedAt } = useChannel({ channelId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    const promotions = ref([{
      key: 0,
      value: 'channel',
      label: Promotion.CHANNEL
    }, {
      key: 1,
      value: 'marketing',
      label: Promotion.MARKETING
    }, {
      key: 2,
      value: 'operating',
      label: Promotion.OPERATING
    }]);

    watch(dataUpdatedAt, () => {
      const channel = data.value.data;
      formValues.value = {
        utmSource: channel.utmSource,
        promotion: channel.promotion,
        // channelAgentId: channel.channelAgent.id,
        note: channel.note
      };
    });

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form &&
        form.validate((valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              update(
                {
                  channelId,
                  data: normalizeRequestData(formValues.value)
                },
                {
                  onSuccess() {
                    ElMessage.success(UPDATED_SUCCESSFULLY_TEXT);

                    formValues.value = DEFAULT_FORM_VALUES;
                    router.go(-1);
                  },
                  onError(error: any) {
                    ElMessage.error({
                      message: error.response?.data.message,
                      type: 'error'
                    });
                    formErrors.value = error.response?.data.message;
                  }
                }
              );

              return;
            }

            create(
              { data: normalizeRequestData(formValues.value) },
              {
                onSuccess() {
                  ElMessage.success(CREATED_SUCCESSFULLY_TEXT);

                  formValues.value = DEFAULT_FORM_VALUES;

                  router.go(-1);
                },

                onError(error: any) {
                  ElMessage.error(error.response?.data.message);
                  formErrors.value = error.response?.data.message;
                }
              }
            );
          }
        });
    };

    const channelAgents = ref<ChannelAgent[]>([]);
    const fetchChannelAgents = async() => {
      channelAgents.value = (await getChannelAgents({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    onMounted(() => {
      fetchChannelAgents();
    });

    return {
      formValues,
      formRef,
      rules,
      isCreating,
      isUpdating,
      formErrors,
      submitForm,
      bindFormItemError,
      channelAgents,
      promotions
    };
  }
});
